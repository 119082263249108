<template>
  <div class="page page-Contacts" :class="{ loaded: isLoaded }">
    <div class="half half-contacts half-reverse content" v-if="!isMobile">
      <!-- images -->
      <template v-for="(tab, index) in $t('lang.contacts')">
        <transition
          :enter-active-class="enterClassName"
          :leave-active-class="leaveClassName"
          :key="tab.id"
        >
          <div
            class="half__pic m25"
            v-if="tab.id === showTab"
            key="index"
            :style="imgs[index].background"
          ></div>
        </transition>
      </template>

      <!-- content -->
      <div class="half__text m50">
        <h2 class="m50">{{ $t('lang.contacts-title') }}</h2>

        <div class="vertical-tabs">
          <template v-for="(tab, index) in $t('lang.contacts')">
            <span
              class="link vertical-tab"
              @click="showTab = tab.id"
              :class="{ active: showTab === tab.id }"
              :key="tab.id"
              >{{ tab.label }}</span
            >
            <br :key="index" />
          </template>
        </div>

        <div class="vertical-tabs__content">
          <template v-for="tab in $t('lang.contacts')">
            <transition
              :enter-active-class="enterClassName"
              :leave-active-class="leaveClassName"
              :key="tab.id"
              v-if="tab.id === showTab"
            >
              <div class="vertical-tab__content" :key="tab.id">
                <VuePerfectScrollbar class="scroll-area" :settings="psSettings">
                  <span v-html="tab.text"></span>
                  <div
                    class="contacts-block"
                    v-if="tab.btns"
                    v-html="tab.btns"
                  ></div>
                </VuePerfectScrollbar>
              </div>
            </transition>
          </template>
        </div>
      </div>
    </div>

    <template v-if="isMobile">
      <v-select
        class="contacts-select"
        @input="changeContactsPage"
        :options="$t('lang.contacts')"
        v-model="selectedMenu"
        :value="$t('lang.contacts')[0]"
        :clearable="false"
        :searchable="false"
      >
        <template slot="open-indicator">
          <img src="../assets/images/arrow-down.svg" />
        </template>
      </v-select>

      <template v-for="(tab, index) in $t('lang.contacts')">
        <transition
          :enter-active-class="enterClassName"
          :leave-active-class="leaveClassName"
          :key="tab.id"
        >
          <div class="page" v-if="tab.id === showTab" key="tab.id">
            <div class="half half-contacts half-reverse content">
              <div class="half__pic m25" :style="imgs[index].background"></div>
              <div class="half__text m50">
                <div
                  v-html="tab.text"
                  v-if="!isMobile"
                  :class="{ 'contacts-block__text-small': tab.isTextSmall }"
                ></div>
                <div
                  v-html="tab.textMobile"
                  v-if="isMobile"
                  :class="{
                    'contacts-block__text-small': tab.isTextMobileSmall,
                  }"
                ></div>
              </div>
            </div>
            <div
              v-if="tab.id !== 'bank'"
              v-html="tab.btns"
              class="contacts-block m75"
            ></div>
            <div v-if="tab.id === 'bank'" class="contacts-block m75">
              <div class="contacts-block__btns hideDesktop">
                <span @click="copyToClipboard" class="btn">{{
                  $t('lang.contacts-copy')
                }}</span>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </template>
  </div>
</template>

<style lang="less">
@import '../assets/css/select2.less';
</style>

<script>
import { loader } from '../mixins/loader';
import { isMobile } from '../mixins/isMobile';
import { functions } from '../mixins/functions';

import ENV from '@/environment';

export default {
  name: 'Contacts',
  mixins: [loader, isMobile, functions],

  data() {
    return {
      pageData: { page: 'Contacts', isHeaderHidden: true },
      enterClassName: 'animated fadeInUp',
      leaveClassName: 'animated fadeOut',
      showTab: 'client-service',
      selectedMenu: this.$t('lang.contacts')[0],
      psSettings: {
        suppressScrollX: true,
      },
      tabs: this.$t('lang.contacts'),
      imgs:
        ENV.REGION === 'by'
          ? [
              {
                backgroundImages: {
                  '2x': '/img/by/4.png',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/by/9.jpg',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/by/5.png',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/by/4.png',
                },
              },
            ]
          : [
              {
                backgroundImages: {
                  '2x': '/img/10@2x.jpg',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/11@2x.jpg',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/11@2x.jpg',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/12@2x.jpg',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/12@2x.jpg',
                },
              },
              {
                backgroundImages: {
                  '2x': '/img/13@2x.jpg',
                },
              },
            ],
    };
  },

  deactivated() {
    this.isLoaded = false;
  },

  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },

  watch: {
    isMobile(val) {
      if (val) {
        this.enterClassName = 'animated slideInRight';
        this.leaveClassName = 'animated slideOutLeft';
      } else {
        this.enterClassName = 'animated fadeInUp';
        this.leaveClassName = 'animated fadeOut';
      }
    },
  },

  methods: {
    onActivated() {
      if (this.imagesLoaded) {
        this.pageLoaded();
      } else {
        const images = [];

        for (const i in this.tabs) {
          this.imgs[i].background = this.bgImgStyle(
            this.imgs[i].backgroundImages,
          );

          for (const j in this.imgs[i].backgroundImages) {
            images.push(this.imgs[i].backgroundImages[j]);
          }
        }

        this.loadImages(images);
      }
    },

    changeContactsPage(val) {
      this.showTab = val.id;
    },
    copyToClipboard() {
      const str = document.querySelector('.toCopy').textContent;
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
  },
};
</script>
